import { Box, Card, Group, Text, Badge, Flex, Image, Title, Avatar } from '@mantine/core';
import React from 'react';
import classes from './ProgramCard.module.css';
import NoImage from '../../../assets/img/no-image.png';
import { getImageFile } from '../../../services/fileImage';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../../plugins/formatPrice';
import { IconMap, IconCalendarEvent, IconClock2, IconMapPinFilled } from '@tabler/icons-react';
import dayjs from 'dayjs';

const ProgramCard = ({ data }) => {
  const navigate = useNavigate();

  const formatTime = (time) => {
    return time.slice(0, -3);
  };

  const mappingSchedule = (type, latestSchedule) => {
    const mappingTypeSchedule = () => {
      if (latestSchedule !== null) {
        if (type === 'offline') {
          return (
            <Group gap="xs" mb={4} wrap="nowrap">
              <IconMap size="1rem" stroke="1.5" color="blue" />
              <Text className={classes.titleSchedule} lineClamp={1}>
                {latestSchedule.address ? latestSchedule.address : '-'}
              </Text>
            </Group>
          );
        } else if (type === 'online') {
          const mappingTypeOnline = latestSchedule.onlineLink;
          const mappingPlatformOnline = (val) => {
            if (val.includes('zoom')) {
              return 'Zoom';
            } else if (val.includes('teams')) {
              return 'Microsoft Teams';
            } else if (val.includes('meet.google')) {
              return 'Google Meet';
            }
          };
          return (
            <Group gap="xs" mb={4} wrap="nowrap">
              <div style={{ color: 'blue' }}>
                <IconMapPinFilled size="1rem" stroke="1.5" />
              </div>
              <Text className={classes.titleSchedule} lineClamp={1}>
                {mappingPlatformOnline(mappingTypeOnline)}
              </Text>
            </Group>
          );
        }
      } else {
        return (
          <Group gap="xs" mb={4}>
            <div style={{ color: 'blue' }}>
              <IconMapPinFilled size="24px" stroke="1.5" />
            </div>
            <Text className={classes.titleSchedule} lineClamp={1}>
              Belum Tersedia
            </Text>
          </Group>
        );
      }
    };

    return (
      <Box className={classes.metaLocation}>
        <Group gap="xs" mb={4}>
          <IconCalendarEvent size="24px" stroke="1.5" color="blue" />
          <Text className={classes.titleSchedule} lineClamp={1}>
            {latestSchedule !== null ? dayjs(latestSchedule.eventStart).format('DD MMMM YYYY') : 'Belum Tersedia'}
          </Text>
        </Group>
        <Group gap="xs" mb={4}>
          <IconClock2 size="24px" stroke="1.5" color="blue" />
          <Text className={classes.titleSchedule} lineClamp={1}>
            {latestSchedule !== null ? `${formatTime(latestSchedule.timeStart)} - ${formatTime(latestSchedule.timeEnd)}` : 'Belum Tersedia'}
          </Text>
        </Group>
        {mappingTypeSchedule()}
      </Box>
    );
  };

  return (
    <Card
      className={classes.programCard}
      h={{base: "70vh", md:"70vh",  lg: "60vh"}}
      shadow="sm"
      radius="md"
      withBorder
      onClick={() => navigate(`/${data.kind}/${data.slug}`)}
    >
      <Card.Section>
        <Image
          style={{ maxHeight: '255px' }}
          src={data.eventImages.length === 0 ? NoImage : getImageFile(data.eventImages[0].filepath)}
        />
      </Card.Section>
      <Flex direction="column" className={classes.contentProgram} justify="space-between" style={{ height: '100%' }}>
        <Box>
          <Flex justify="space-between">
            <Text fs="16px" c="#B0B2CB" tt="capitalize">
              {data.kind}
            </Text>
            <Badge
              mt={5}
              color="#F3F6FF"
              variant="filled"
              size="xs"
              mb="xs"
              className={classes.typeProgram}
            >
              {data.type}
            </Badge>
          </Flex>
          <Box mt={5}>
            <Title order={2} className={classes.titleProgram} lineClamp={2}>
              {data.name}
            </Title>
          </Box>
          {data.type === 'course' ? (
            <Flex direction="row" mt={5}>
              <Avatar
                variant="white"
                 // src={!isLoading ? memberData.image !== null ? getImageFile(memberData.image) : null : null}  adjust to consume API
                src={'https://i.pravatar.cc/30'}
                radius="xl"
                size="md"
              />
              <Box ml="1rem">
                <Text fw="bold">Bagus Febrianto</Text>
                <Text c="gray">DLH Provinsi Jawa Timur</Text>
              </Box>
            </Flex>
          ) : (
            <Box mt={10}>
              {mappingSchedule(data.type, data.latestSchedule)}
            </Box>
          )}
        </Box>
        <Box>
          <Flex align="center" className={classes.textLineThrought} h={20}>
            <Text className={classes.blinkPrice} c="red">
              {data.latestSchedule !== null && data.latestSchedule.price < data.price ? formatPrice(data.price) : ''}
            </Text>
          </Flex>
          <Flex align="center">
            <Text className={classes.priceProgram} c="red">
              {data.latestSchedule !== null ? formatPrice(data.latestSchedule.price) : 'Belum Tersedia'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Box
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          width: '100%',
          height: '8px',
          background: '#4258FB',
          borderRadius: '0 0 4px 4px',
        }}
      />
    </Card>
  );
};

export default ProgramCard;
