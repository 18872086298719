import React from 'react';
import { Box, Container, Grid, Text, Card } from '@mantine/core';
import classes from '../../../pages/Home/Home.module.css';

const IntroSection = () => {
  return (
    <Box style={{ position: 'relative', backgroundColor: '#F3F6FF' }} py={50}>
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '200px',
          backgroundColor: 'white',
          clipPath: 'polygon(0 100%, 100% 0, 100% 100%, 0 100%)',
          zIndex: 0,
        }}
      />
      <Container fluid className={classes.section} style={{ position: 'relative', zIndex: 1 }}>
        <Box className={classes.paddingPage}>
          <Grid align="start" gutter="xl">
            {[{
              title: "Acceleration",
              description: "Mempercepat perkembangan kompetensi individu di dunia kerja"
            }, {
              title: "Capacity Building",
              description: "Meningkatkan kemampuan individu dengan modul terkini dan tutor berkompeten"
            }, {
              title: "Professional",
              description: "Memberikan Pelatihan yang professional dan sesuai dengan kebutuhan perusahaan"
            }, {
              title: "Collaborative",
              description: "Berkerjasama dengan berbagai perusahaan dalam berbagai program"
            }].map((item, index) => (
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }} key={index}>
                <Card
                  padding="lg"
                  radius="lg" 
                  style={{
                    position: 'relative',
                    backgroundColor: 'white',
                    boxShadow: '0px 10px 30px rgba(134, 118, 255, 0.2)', 
                    overflow: 'hidden', 
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      top: '-7rem', 
                      right: '-5rem',
                      width: '180px', 
                      height: '180px',
                      borderRadius: '50%',
                      transform: 'rotate(-120deg)',
                      opacity: '12%',
                      background: 'linear-gradient(-120deg, #B2A2FF, transparent)',
                      zIndex: 0,
                    }}
                  />
                  <Box style={{ position: 'relative', zIndex: 1 }}>
                    <Text className={classes.titleIntro} mb={8} style={{ fontWeight: '600', fontSize: '18px' }}>
                      {item.title}
                    </Text>
                    <Text c="#828282" fz={12} fw={500}>
                      {item.description}
                    </Text>
                  </Box>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default IntroSection;
