import React from 'react';
import classes from './PublicFooter.module.css';
import { Box, Container, Grid, Text, Flex, Image, Group, Title, Divider } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import TandeemLogo from '../../../assets/img/tandem-logo.svg';
import { IconPhoneFilled, IconMailFilled, IconMapPinFilled } from '@tabler/icons-react';

const PublicFooter = () => {
  const navigate = useNavigate();

  const toRoutePage = (page) => {
    return navigate(page);
  };


  // const openLink = (link) => {
  //   window.open(`${link}`, '_blank');
  // };
  return (
    <Box>
      <Container fluid>
        <Grid px={{ base: '20px', md: '80px' }} py={24}>
          <Grid.Col span={{ base: 12, lg: 4 }} mb={30}>
            <Box>
              <Flex direction="column" align="flex-start" mt={6}>
                <Image src={TandeemLogo} maw="200px" />
                <Text mt={20} fz={{ base: '16px', md: '24px' }}>
                  Meningkatkan Bisnis Anda ke Tingkat Berikutnya dengan Teknologi
                </Text>
              </Flex>
            </Box>
          </Grid.Col>

          <Grid.Col span={{ base: 12, lg: 8 }}>
            <Grid>
              <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                <Title c="gray" order={2} fw="bold" fz={{ base: '18px', md: '20px' }}>
                  Sitemap
                </Title>
                <Divider size="sm" color="#4258FB" w="100px" mb={20} />
                <Box mt={6}>
                  <Text
                    className={classes.routeLink}
                    fz={{ base: '14px', md: '16px' }}
                    mb={4}
                    onClick={() => toRoutePage('/training')}
                  >
                    Home
                  </Text>
                  <Text
                    className={classes.routeLink}
                    fz={{ base: '14px', md: '16px' }}
                    mb={4}
                    onClick={() => toRoutePage('/bootcamp')}
                  >
                    Training
                  </Text>
                  <Text
                    className={classes.routeLink}
                    fz={{ base: '14px', md: '16px' }}
                    mb={4}
                    onClick={() => toRoutePage('/bootcamp')}
                  >
                    E-Learning
                  </Text>
                </Box>
              </Grid.Col>

              <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                <Box mt={6}>
                  <Text
                    className={classes.routeLink}
                    fz={{ base: '14px', md: '16px' }}
                    mb={4}
                    onClick={() => toRoutePage('/about')}
                  >
                    Bootcamp
                  </Text>
                  <Text
                    className={classes.routeLink}
                    fz={{ base: '14px', md: '16px' }}
                    mb={4}
                    onClick={() => toRoutePage('/about')}
                  >
                    About
                  </Text>
                  <Text
                    className={classes.routeLink}
                    fz={{ base: '14px', md: '16px' }}
                    mb={4}
                    onClick={() => toRoutePage('/blog')}
                  >
                    Blog
                  </Text>
                </Box>
              </Grid.Col>

              <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                <Title c="gray" order={2} fw="bold" fz={{ base: '18px', md: '20px' }}>
                  Kontak Kami
                </Title>
                <Divider mb={20} size="sm" color="#4258FB" w="100px" />
                <Box mt={6}>
                  <Flex dir="row" gap={10}>
                    <div style={{ color: '#B0B2CB' }}>
                      <IconPhoneFilled width={24} height={24} />
                    </div>
                    <Text
                      className={classes.routeLink}
                      fz={{ base: '14px', md: '16px' }}
                      mb={4}
                      onClick={() => toRoutePage('/terms-and-condition')}
                    >
                      081 231 600 688
                    </Text>
                  </Flex>
                  <Flex dir="row" gap={10}>
                    <div style={{ color: '#B0B2CB' }}>
                      <IconMailFilled width={24} height={24} />
                    </div>
                    <Text
                      className={classes.routeLink}
                      fz={{ base: '14px', md: '16px' }}
                      mb={4}
                      onClick={() => toRoutePage('/privacy-policy')}
                    >
                      tandeem@gmail.com
                    </Text>
                  </Flex>
                  <Flex dir="row" gap={10}>
                    <div style={{ color: '#B0B2CB' }}>
                      <IconMapPinFilled width={24} height={24} />
                    </div>
                    <Text
                      className={classes.routeLink}
                      fz={{ base: '14px', md: '16px' }}
                      mb={4}
                      onClick={() => toRoutePage('/validation-certificate')}
                    >
                      Cyber 2 Tower 33rd Floor Jl. HR Rasuna Said X5 No. 16 Jakarta Selatan
                    </Text>
                  </Flex>
                </Box>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Container>

      <Box bg="#4258FB" py={20} px={{ base: 20, md: 40 }} width="100%">
        <Flex justify="space-between" align="center" mx="auto" maxW="1200px">
          <Text c="white" fw="bold" fz={{ base: '12px', md: '14px' }}>
            Copyright © 2024. Tandeem. All Right Reserved.
          </Text>
          <Group spacing={0} noWrap>
            <Text
              c="white"
              opacity="58%"
              style={{ cursor: 'pointer' }}
              fw="600"
              fz={{ base: '12px', md: '16px' }}
              onClick={() => toRoutePage('/faq')}
            >
              FAQ
            </Text>
            <Text
              c="white"
              opacity="58%"
              style={{ cursor: 'pointer' }}
              fw="600"
              fz={{ base: '12px', md: '16px' }}
              onClick={() => toRoutePage('/terms-and-condition')}
            >
              Syarat & Ketentuan
            </Text>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default PublicFooter;
