import React from 'react'
import classes from './PublicHeader.module.css'
import { Box, Burger, Divider, Flex, Group, Button, Transition, Paper, Menu, Avatar, Text } from '@mantine/core'
import TandeemLogo from "../../../assets/img/tandem-logo.svg"
import { useNavigate, useLocation } from 'react-router-dom'
import { useDisclosure } from '@mantine/hooks'
import { useSelector } from 'react-redux' 
import { IconGauge, IconDoorExit } from '@tabler/icons-react'
import { getImageFile } from '../../../services/fileImage'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { update } from '../../../store/authData'

const links = [
  {
    link: '/',
    label: 'Home'
  },
  {
    link: '/training',
    label: 'Training'
  },
  {
    link: '/elearning',
    label: 'E-Learning'
  },
  {
    link: '/bootcamp',
    label: 'Bootcamp'
  },
  {
    link: '/about',
    label: 'Tentang'
  },
]

const PublicHeader = () => {
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const { isLoading, data } = useSelector(state => state.member)
  const { isLogin } = useSelector(state => state.auth)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [opened, { toggle, close }] = useDisclosure(false)

  const goRoute = (route) => {
    navigate(`${route}`)
  }

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.link}
      data-active={link.link === pathname || undefined}
      onClick={(event) => {
        event.preventDefault()
        navigate(link.link)
        close()
      }}
    >
      {link.label}
    </a>
  ))

  const logoutUser = () => {
    cookies.remove("session")
    dispatch(update({ 'authorization': null, 'isLogin': false, 'isLoading': false }))
    navigate('/')
  }

  const verifiedLogin = (isAuthenticate, memberData ) => {
    if (!isLoading) {
      if (!isAuthenticate) {
        return (
          <Box className={classes.publicButton}>
            <Group justify='center' >
              <Button variant="subtle" color="#4258FB" fw={"700"} fs={"16px"} size="sm" ml="xs" onClick={() => {navigate('/register')}}>Register</Button>
              <Button color="#4258FB" size="sm" variant='outline' radius={"xl"} onClick={() => {navigate('/login')}}>Login</Button>
            </Group>
          </Box>
        )
      } else {
        return (
          <Box style={{ display: 'flex' }}>
            <Divider size="xs" mx="md" orientation="vertical" />
            <Menu position='bottom-end' offset={3}>
              <Menu.Target>
                <Box style={{ cursor: 'pointer' }}>
                  <Flex align="center">
                    <Avatar variant='white' src={!isLoading ? memberData.image !== null ? getImageFile(memberData.image) : null : null} radius="xl" size="md" />
                  </Flex>
                </Box>
              </Menu.Target>
              <Menu.Dropdown style={{ border: '0px', padding: '0px', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                <Box className={classes.bgHeaderDropdown} />
                <Box style={{ width: '16rem', padding: '6px' }}>
                  <Box className={classes.menuMember}>
                    <Avatar variant='white' src={!isLoading ? memberData.image !== null ? getImageFile(memberData.image) : null : null} radius="xl" size="lg" mr={12} />
                    <Text fz="sm" ml={8} mt={6} fw="bold">{!isLoading ? memberData.nickname !== null ? memberData.nickname : memberData.fullName : ''}</Text>
                  </Box>
                  <Box mt={70}>
                    <Menu.Item leftSection={<IconGauge size={14}/>} onClick={() => goRoute('/dashboard')}>Dashboard</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item color='red' leftSection={<IconDoorExit size={14}/>} onClick={logoutUser}>Logout</Menu.Item>
                  </Box>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
        )
      }
    }
  }

  const mappingAuthMobile = (isAuthenticate) => {
    if (!isLoading) {
      if (!isAuthenticate) {
        return (
          <Box p={10} className={classes.burger}>
            <Divider size="xs" mb="md" />
            <Group spacing="sm" grow >
              <Button variant="outline" color="yellow" size="xs" ml="xs" onClick={() => {navigate('/login')}}>Masuk</Button>
              <Button color="#E72E12" size="xs" onClick={() => {navigate('/register')}}>Daftar</Button>
            </Group>
          </Box>
        )
      }
    }
    
  }

  return (
    <header className={classes.root}>
      <Box className={classes.sectionHeader}>
        <img src={TandeemLogo} className={classes.logoSize} alt="laju-reaksi-logo" onClick={() => navigate('/')} />
        <Flex align='center'>
          <Burger color='#FFFF' opened={opened} onClick={toggle} className={classes.burger} size='sm' />
          <Box spacing={10} className={classes.linkHeader}>
            {items}
          </Box>
        </Flex>

        {verifiedLogin(isLogin, data)}

        <Transition transition="scale-y" duration={250} mounted={opened}>
        {(styles) => (
          <Paper className={classes.dropdown} withBorder style={styles}>
            {items}
            {mappingAuthMobile(isLogin)}
          </Paper>
        )}
        </Transition>
      </Box>
    </header>
  )
}

export default PublicHeader