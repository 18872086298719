import React from 'react'
import { Box, Container, Image, Text, Title } from '@mantine/core'
import classes from '../../../pages/Home/Home.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import ValeLogo from '../../../assets/img/LogoPartner/Vale_logo.png'
import AsahimasLogo from '../../../assets/img/LogoPartner/Asahimas_logo.png'
import DpkuLogo from '../../../assets/img/LogoPartner/DKPU_logo.png'
import IndahKiatLogo from '../../../assets/img/LogoPartner/IndahKiat_logo.png'
import KeiLogo from '../../../assets/img/LogoPartner/KEI_logo.png'
import KidecoLogo from '../../../assets/img/LogoPartner/Kideco_logo.png'
import PertaminaLogo from '../../../assets/img/LogoPartner/Pertamina_logo.png'
import PetLogo from '../../../assets/img/LogoPartner/PET_logo.png'
import PetroLogo from '../../../assets/img/LogoPartner/Petro_logo.png'
import PlnLogo from '../../../assets/img/LogoPartner/Pln_logo.png'
import PpnsLogo from '../../../assets/img/LogoPartner/PPNS_logo.png'
import PremierOilLogo from '../../../assets/img/LogoPartner/PremierOil_logo.png'
import TeklinkLogo from '../../../assets/img/LogoPartner/TekLing_logo.png'
import UnairLogo from '../../../assets/img/LogoPartner/Unair_logo.png'
import UpnLogo from '../../../assets/img/LogoPartner/UPN_logo.png'
import KangeanLogo from '../../../assets/img/LogoPartner/Kangean_logo.png'
import TekIndLogo from '../../../assets/img/LogoPartner/TekInd_logo.png'
import '../../../assets/css/slidercustom.css'


const PartnerSection = () => {
  return (
    <Box className={classes.defaultSection} mb={{ base: 0, lg: 140 }}>
      <Container fluid className={classes.section}>
        <Box className={classes.paddingPage} align="center">
          <Title order={1} mb={8}>Partner</Title>
          <Text fz="lg" fw={400} c={"#57586C"} mb={50}>yang telah mendukung dan menggunakan Jasa Pelatihan Kami</Text>
        </Box>
      </Container>
      <Swiper 
        slidesPerView={1}
        watchSlidesProgress={true}
        loop={true}
        style={{filter: "grayscale(1)"}}
        autoplay= {{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 15,
          },
        }}
        modules={[ Autoplay ]}
      >
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={ValeLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={AsahimasLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={DpkuLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={IndahKiatLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={KeiLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={KidecoLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={PertaminaLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={PetLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={PetroLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={PlnLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={PpnsLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={PremierOilLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={TeklinkLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={UnairLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={UpnLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={KangeanLogo} />
        </SwiperSlide>
        <SwiperSlide>
            <Image classname={classes.logoPartner} src={TekIndLogo} />
        </SwiperSlide>
      </Swiper>
    </Box>
  )
}

export default PartnerSection