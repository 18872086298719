import React from "react";
import { Divider, Text, Flex, Image, Title, Stack, Card, Box, Button } from "@mantine/core";
import bgImage from "../../../assets/img/bg-image.svg";
import Quote from "../../../assets/img/quote-left.svg";
import { IconChevronRight, IconChevronLeft } from "@tabler/icons-react";

const TestimoniSection = () => {
  return (
    <Flex direction={{lg: "row", base: "column"}} wrap="wrap">
      <Stack ml={100} style={{ position: "relative" }}>
        <Image style={{ minWidth: "50%" }} src={bgImage} width={"500px"} />
        <Stack style={{ position: "absolute", zIndex: 1, marginTop: "1rem" }}>
          <Title
            sx={(theme) => ({
              fontSize: "40px",
              [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                fontSize: "60px",
              },
            })}
            fontWeight="bold"
          >
            Testimoni
          </Title>
          <Text
            sx={(theme) => ({
              fontSize: "16px",
              [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                fontSize: "24px",
              },
            })}
            fontWeight="400"
          >
            Dari alumni yang telah mengikuti program-program dari Tandeem
          </Text>
        </Stack>
      </Stack>
      <Card
        shadow="xl"
        mt={40}
        style={{
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        }}
        p={"5rem"}
        maw={{lg: "60%", base: "100%"}}
        pos={{lg: "absolute", base: "relative"}}
        left={"auto"}
        right={0}
      >
        <Flex dir="row" justify={"space-between"}>
          <Box>
            <Image src={Quote} maw={"40%"} />
            <Title
              order={2}
              fw={"bold"}
              mt={40}
              c={"#4F4F4F"}
              sx={(theme) => ({
                fontSize: "20px",
                [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                  fontSize: "28px",
                },
              })}
            >
              Arya Surya Satria
            </Title>
            <Flex gap={10} dir="row" mt={10}>
              <Divider size="md" color="#4258FB" w="50px" mt={12} />
              <Text
                sx={(theme) => ({
                  fontSize: "14px",
                  [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                    fontSize: "16px",
                  },
                })}
              >
                Director TrustMedis
              </Text>
            </Flex>
          </Box>
          <Box>
            <Image
              variant="white"
              width={"200px"}
              height={"200px"}
              // src={!isLoading ? memberData.image !== null ? getImageFile(memberData.image) : null : null}  adjust to consume API
              src={"https://i.pravatar.cc/200"}
              radius={"sm"}
              size="xl"
            />
          </Box>
        </Flex>
        <Text
          sx={(theme) => ({
            fontSize: "16px",
            [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
              fontSize: "20px",
            },
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              fontSize: "24px",
            },
          })}
          mt={20}
        >
          Saya sangat bersemangat dan termotivasi setelah mengikuti program tersebut, saya jadi punya planning untuk
          kedepannya.
        </Text>
        <Flex dir="row" gap={40} mt={40}>
          <Button
            pt={30}
            pb={30}
            pr={5}
            bg={"#B0B2CB"}
            leftSection={<IconChevronLeft width={50} height={50} />}
          />
          <Button
            pt={30}
            pb={30}
            pr={5}
            bg={"#B0B2CB"}
            leftSection={<IconChevronRight width={50} height={50} />}
          />
        </Flex>
      </Card>
    </Flex>
  );
};

export default TestimoniSection;
