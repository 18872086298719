import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProgramList } from '../../../services/program'
import { Box, Container, Flex, Text, Button, Grid, Badge } from '@mantine/core'
import { IconCircleArrowRight, IconArrowRight } from '@tabler/icons-react'
import SkeletonCard from '../../ui/SekeletonCard'
import ProgramCard from '../Program/ProgramCard'
import classes from '../../../pages/Home/Home.module.css'


const defaultCaption = {
  'training' : {
    title: 'Training',
    caption: 'Program pelatihan dan webinar dari Tandeem'
  },
  'bootcamp' : {
    title: 'Bootcamp',
    caption: 'Program belajar intensif dengan video pembelajaran dengan Tutor berpengalaman'
  },
  'course' : {
    title: 'Course',
    caption: 'Program belajar intensif dengan video pembelajaran dengan Tutor berpengalaman'
  }
}

const TrainingSection = ({ type }) => {
  const params = {
    take: 4,
    kind: type
  }
  const navigate = useNavigate()
  const [programList, setProgramList] = useState([])
  const [ loading, isLoading ] = useState(true)

  const handleGetProgramList = async () => {
    isLoading(true)
    try {
      const response = await getProgramList(params)
      setProgramList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    handleGetProgramList()
    // eslint-disable-next-line
  }, [])

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Grid.Col span={{base: 12, md: 6, lg: 3 }} key={i}>
        <SkeletonCard />
      </Grid.Col>
    )
  })

  const mappingDataList = (program) => {
    if (program.length > 0) {
      const remap = program.map((val, index) => {
        return (
          <Grid.Col span={{base: 12, md: 6, lg: 4 }} key={index}>
            <ProgramCard data={val} />
          </Grid.Col>
        )
      })
      return remap
    }
  }

  return (
    <Box className={type === 'training' || type === 'bootcamp' ?  classes.defaultSection : classes.bgGraySection}>
      <Container fluid className={classes.section}>
        <Flex className={classes.paddingPage} justify="space-between" align="center">
          <Box>
            {type === "course" ? null :
              <Badge bg="#F3F6FF" c={"#4258FB"} size='md' mb='xs' className={classes.typeProgram}>
                {type}
              </Badge>
            }
            <Text className={classes.titleSection} mb={6}>{defaultCaption[type].title}</Text>
            <Text className={classes.captionSection}>{defaultCaption[type].caption}</Text>
          </Box>
          <Button variant="transparent" c={"#4258FB"} fz={{lg: "24px", base: "sm"}} rightSection={<IconArrowRight color='#4258FB' size={"24px"}/>} radius={12} onClick={() => navigate(`/${params.kind}`)}>Selengkapnya</Button>
        </Flex>
        <Box mt={20}>
          <Grid gutter='5rem' spacing className={classes.gridProgramCard} align='center'>
            {loading ? loadingData(4) : mappingDataList(programList)}
          </Grid>
        </Box>
        <Flex mt={50} justify="center" align="center">
          <Button hiddenFrom='md' size='xs' rightSection={<IconCircleArrowRight size={14}/>} color='yellow.7' radius={10} onClick={() => navigate(`/${params.kind}`)}>Selengkapnya</Button>
        </Flex>
      </Container>
    </Box>
  )
}

export default TrainingSection