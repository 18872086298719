import React from "react";
import { Box, Button, Text, Flex, Image, Title } from "@mantine/core";

const ConsultantSection = () => {
  return (
    <Box
      mx={{lg: 100, base: 20}}
      mt={{lg: 300, base: 200}}
      mb={100}
      style={{
        position: "relative",
        backgroundColor: "#4258FB",
        padding: "20px",
        paddingBottom: "8rem",
        borderRadius: "32px",
        display: "flex",
        gap: "24px",
        alignItems: "center",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "-177px", 
          right: "-100px",
          width: "498px", 
          height: "498px", 
          opacity: "15%",
          background: "linear-gradient(135deg, #ffffff 0%, #ffffff00 100%)",
          borderRadius: "50%",
          zIndex: 0,
        }}
      />
      <Box
        style={{
          position: "absolute",
          top: "286px", 
          left: "267px",
          width: "498px", 
          height: "498px", 
          opacity: "15%",
          background: "linear-gradient(135deg, #ffffff 0%, #ffffff00 100%)",
          borderRadius: "50%",
          zIndex: 0,
        }}
      />
      <Flex gap="16px" style={{ flexWrap: "wrap" }}>
          <Image
            src="https://ik.imagekit.io/ymj4jtqnt/tandeem/image-dummy1.png?updatedAt=1733983396517" 
            alt="Consultation"
            fit="cover"
            height="100%"
            mb={{base: "5rem"}}
          />
          <Image
            src="https://ik.imagekit.io/ymj4jtqnt/tandeem/image-dummy2.png?updatedAt=1733983396532"
            alt="Team Meeting"
            fit="cover"
            height="100%"
            right={{lg: "18rem", base: "-2rem"}}
            top={{lg: "10rem", base: "-14rem"}}
            style={{zIndex: 1}}
            pos={"relative"}
          />
      </Flex>
      <Box style={{ maxWidth: "550px", marginLeft: "-200px", marginTop: "5rem", textAlign: "left" }} mt={{base: "8rem"}}>
        <Title
          fz={{lg: "40px", base: "24px"}}
          mt={{base: "12rem"}}
          style={{
            fontWeight: 700,
            color: "#FFFFFF",
            marginBottom: "16px",
          }}
        >
          Konsultasikan dengan kami!
        </Title>
        <Text
          fz={{
            base: "15px",
            lg: "24px"
          }}
          style={{
            color: "#F0F4FF",
            marginBottom: "24px",
          }}
        >
          Ayo, Daftarkan diri anda sekarang juga, jika ingin bertanya, klik
          saja tombol dibawah ini.
        </Text>
        <Button
          size="lg"
          radius="xl"
          style={{
            backgroundColor: "#FFFFFF",
            color: "#0056D2",
            fontWeight: "bold",
          }}
        >
          Hubungi Kami
        </Button>
      </Box>
    </Box>
  );
};

export default ConsultantSection;