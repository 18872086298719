import React from 'react';
import { Box, Container, Text, Button, Image, Flex } from '@mantine/core';
import artImage from '../../../assets/img/artImage.svg';
import classes from '../../../pages/Home/Home.module.css';

const HeaderSection = ({ handleScroll }) => {
  return (
    <Box
      className={classes.headerSection}
      style={{ backgroundColor: `#F3F6FF`, padding: '40px 0' }}
    >
      <Container fluid className={classes.section}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          align="center"
          className={classes.innerHeader}
          style={{ gap: '24px' }}
        >
          <Box
            className={classes.contentHeader}
            style={{ maxWidth: '600px' }}
            sx={(theme) => ({
              textAlign: 'center',
              [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                textAlign: 'left',
              },
            })}
          >
            <Text
              className={classes.titleHeader}
              mb={16}
              size={{ base: 24, lg: 32 }}
              weight={700}
              sx={(theme) => ({
                fontSize: '24px',
                [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                  fontSize: '32px',
                },
              })}
            >
              Penyedia Jasa Pelatihan dan Kompetensi Bidang Lingkungan
            </Text>
            <Text
              className={classes.captionHeader}
              mb={24}
              size={16}
              color="dimmed"
              sx={(theme) => ({
                fontSize: '14px',
                [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                  fontSize: '18px',
                },
              })}
            >
              Bersama kami, optimalkan potensi sumber daya manusia dan lingkungan untuk masa depan yang lebih baik dan
              berkelanjutan
            </Text>
            <Button
              className={classes.buttonHeader}
              size="lg"
              radius="xl"
              onClick={handleScroll}
              style={{ padding: '12px 24px' }}
              sx={(theme) => ({
                width: '100%',
                [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                  width: 'auto',
                },
              })}
            >
              <Text fw="bold">Get Started</Text>
            </Button>
          </Box>
          <Box
            right={{
              lg: '-8rem',
              base: "-2rem"
            }}
            style={{
              position: 'relative',
              margin: '0 auto',
            }}
            sx={(theme) => ({
              [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                margin: 0,
                position: 'absolute',
              },
            })}
          >
            <Image
              src={artImage}
              alt="Illustration"
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
              sx={(theme) => ({
                width: '80%',
                [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                  width: '100%',
                },
              })}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default HeaderSection;
